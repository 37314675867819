.VesselMapDateSelect .rdrCalendarWrapper {
  background-color: oklch(var(--p));
}

.VesselMapDateSelect .rdrMonthPicker select {
  color: oklch(var(--pc));
}

.VesselMapDateSelect .rdrMonthPicker select:hover {
  background: oklch(var(--s));
}

.VesselMapDateSelect .rdrYearPicker select {
  color: oklch(var(--pc));
}

.VesselMapDateSelect .rdrYearPicker select:hover {
  background: oklch(var(--s));
}

.VesselMapDateSelect .rdrMonthPicker {
  position: relative;
}
.VesselMapDateSelect .rdrMonthPicker:after {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  position: absolute;
  top: 45%;
  right: 10px;
  content: '';
  z-index: 99;
}
.VesselMapDateSelect .rdrMonthPicker select {
  border: 0;
  position: relative;
  z-index: 98;
  background: oklch(var(--p));
  text-align: left;
}

.VesselMapDateSelect .rdrYearPicker {
  position: relative;
}
.VesselMapDateSelect .rdrYearPicker:after {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  position: absolute;
  top: 45%;
  right: 10px;
  content: '';
  z-index: 99;
}
.VesselMapDateSelect .rdrYearPicker select {
  border: 0;
  position: relative;
  z-index: 98;
  background: oklch(var(--p));
  text-align: left;
}

.VesselMapDateSelect .rdrWeekDay {
  color: oklch(var(--pc));
}

.VesselMapDateSelect .rdrDayNumber span {
  color: oklch(var(--pc));
}

.VesselMapDateSelect .rdrDayPassive .rdrDayNumber span {
  opacity: 0.4;
}

.VesselMapDateSelect .rdrDayToday .rdrDayNumber span::after {
  background-color: oklch(var(--a));
}

.VesselMapDateSelect select option {
  background: oklch(var(--p));
}
