.Header__links {
  background: #003347;
  position: relative;
  overflow: hidden;
}

.Header__links:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-top: 60px solid white;
  border-right: 30px solid transparent;
}
