.map__marker__popup {
  z-index: 4;
}

.map__marker__popup .mapboxgl-popup-tip {
  border-bottom-color: oklch(var(--p) / 0.9);
}

.map__marker__popup .mapboxgl-popup-content {
  color: oklch(var(--pc));
  background-color: oklch(var(--p) / 0.9);
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
}
