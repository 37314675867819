@import '~flag-icons/css/flag-icons.min.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Roboto', sans-serif;
  }
}

/* Customize DaisyUI components */
.btn {
  @apply no-animation;
  @apply normal-case;
  @apply font-normal;
  border-radius: 4px;
}

.modal-box {
  @apply rounded-none;
}

.checkbox {
  @apply no-animation;
}
